import styled from 'styled-components';
import face from '../Images/face.jpg';

const StyledFace = styled.button`
	width: 250px;
	height: 250px;
	font-size:30px;
	color: #fff;
	background: none;
	border: none;
	border-radius: 50%; 
	position: relative;
	z-index: 0;
	transition: .3s;
	float: right;
	cursor: default !important;
	text-align: center;
	
	@media (max-width: 767px) {
		float: none;
	}
		
	&:before {
		content: "";
		position: absolute;
		inset: -8px;
		padding: 10px;
		border-radius: 50%; 
		background: conic-gradient(
					#EB7500,
					#0000 30deg 120deg,
					#00C3FF 150deg 180deg,
					#0000 210deg 300deg,
					#EB7500 330deg
			);
		-webkit-mask:
			linear-gradient(#000 0 0) content-box,
			linear-gradient(#000 0 0);
		-webkit-mask-composite: xor;
						mask-composite: intersect
	}
	&:after {
		content: "";
		position: absolute;
		inset: -100px;
		background:
			radial-gradient(150px at left  150px top 75px,#EB7500 100%,#0000),
			radial-gradient(150px at right 150px bottom 75px,#00C3FF 100%,#0000);
		filter: blur(120px);
		opacity: .5;
	}
	
	&:before,
	&:after {
		animation: rotate 10000s linear infinite;
		box-shadow: 0 0 0 1px #666;
	}
	
	@keyframes rotate {
		100% {
			transform: rotate(180000deg);
		}
	}
	
	&::before,
	&::after {
		transform: rotate(3600deg);
		transition: .5s,600s linear transform;
	}
	&:before {
		background-color: #222;
			border: 2px solid #333;
	}
	
	&>img{
			border-radius: 50%;
			position: absolute;
			transform: translateX(-50%) translateY(-50%);
			width: 242px;
			margin: auto;
			opacity:1;
			z-index: 999;
	}
`;


export const Face = ({style}) => <div style={{textAlign: "center", ...style}}>
	<StyledFace>
		<img src={face} />
	</StyledFace>
</div>;
