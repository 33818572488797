import styled from "styled-components";
import {motion} from "framer-motion";
import {useRef, useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";

const Input = styled(motion.input)`
	display: block;
	width: 100%;
	background: transparent;
	border: none;
	border-bottom: 1px solid rgba(255,255,255,0.5);
	padding: 0rem 0.2rem 1rem;
	font-size: 1.2rem;
	color: #fff;
	margin-bottom: 2rem;
	background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.1));
	transition: .2s ease-in all;
	
	&:focus {
    outline: none;
	}
	
	&.touched:invalid {
		border-color: rgba(255,0,0,0.4);
	}
`;

const Textarea = styled(motion.textarea)`
	display: block;
	width: 100%;
	background: transparent;
	border: none;
	border-bottom: 1px solid rgba(255,255,255,0.5);
	padding: 0rem 0.2rem 1rem;
	font-size: 1.2rem;
	color: #fff;
	margin-bottom: 2rem;
	background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.1));
	transition: .2s ease-in all;
	
	&:focus {
    outline: none;
	}
	
	&.touched:invalid {
		border-color: rgba(255,0,0,0.4);
	}
`;

const Button = styled(motion.button)`
	display: inline-block;
	float: right;
	background: transparent;
	border: 2px solid #00C3FF;
	color: #00C3FF;
	border-radius: 5%;
	text-transform: uppercase;
	font-size: 1.4rem;
	font-weight: bold;
	transition: .2s ease-in all;
	padding: 0.5rem 1.5rem;
	
	&:hover {
		color: #fff;
		background: #00C3FF;
	}
`;

const FormVariants = {
	initial: { opacity: 1 },
	animate: {
		opacity: 1,
		transition: {
			delay: 0.5,
			staggerChildren: 0.075,
		},
	},
};

const FormInputVariants = {
	initial: { y: 50, opacity: 0 },
	animate: { y: 0, opacity: 1 },
};

export const ContactForm = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const [nameTouched, setNameTouched] = useState(false);
	const [emailTouched, setEmailTouched] = useState(false);
	const [messageTouched, setMessageTouched] = useState(false);

	const toastId = useRef(null);

	const submit = () => {
		toastId.current = toast("Submitting...", {
			autoClose: false,
			position: toast.POSITION.BOTTOM_RIGHT,
			theme: "dark",
		});

		axios.post('/api/contact', {name, email, message})
		.then(response => {
			if (response.data.success === false) {
				toast.update(toastId.current, {
					render: "An error occurred, please try again",
					type: toast.TYPE.ERROR,
					autoClose: 5000,
				});
				return;
			}

			setName('');
			setEmail('');
			setMessage('');

			setNameTouched(false);
			setEmailTouched(false);
			setMessageTouched(false);

			toast.update(toastId.current, {
				render: "Message received!",
				type: toast.TYPE.SUCCESS,
				autoClose: 5000
			});
		});
	}

	return (
		<motion.div variants={FormVariants} initial={"initial"} whileInView={"animate"}>
			<Input
				required
				variants={FormInputVariants}
				value={name}
				type={"text"}
				placeholder={"Your name"}
				onChange={(e) => setName(e.target.value)}
				onBlur={_ => setNameTouched(true)}
				className={nameTouched ? 'touched' : ''}
			/>
			<Input
				required
				variants={FormInputVariants}
				value={email}
				type={"email"}
				placeholder={"Your email address"}
				onChange={(e) => setEmail(e.target.value)}
				onBlur={_ => setEmailTouched(true)}
				className={emailTouched ? 'touched' : ''}
			/>
			<Textarea
				required
				variants={FormInputVariants}
				value={message}
				placeholder={"Your message"}
				onChange={(e) => setMessage(e.target.value)}
				onBlur={_ => setMessageTouched(true)}
				className={messageTouched ? 'touched' : ''}
			/>
			<Button variants={FormInputVariants} onClick={() => submit()}>Send</Button>
		</motion.div>
	);
}
