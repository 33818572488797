import {Col, Container, Row} from "react-bootstrap";
import {RevealText} from "../Components/Text";
import {PlainSection} from "../Components/Section";
import {ContactForm} from "../Components/ContactForm";
import styled from "styled-components";

const H2 = styled.h2`
	font-size: 3rem;
	
	@media (max-width: 767px) {
		margin-bottom: 2rem;
	}
`;

export const ContactSection = () => {
	return (
		<PlainSection>
			<Container>
				<Row>
					<Col md={4}>
						<RevealText>
							<H2>Contact</H2>
						</RevealText>
					</Col>
					<Col md={8}>
						<ContactForm />
					</Col>
				</Row>
			</Container>
		</PlainSection>
	);
}
