import {useEffect, useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {motion} from 'framer-motion';

const StyledInstagramPost = styled(motion.div)`
	width: 25vh;
	height: 25vh;
	overflow: hidden;
	text-align: center;
	position: relative;
	display: inline-block;
	margin: 1rem;
	box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
	
	@media (max-width: 767px) {
		width: 30vw;
		height: 30vw;
		margin: 0.2rem;
	}
	
	div.bg {
		width: 100%;
		height: 100%;
		filter: blur(1.5rem);
		background-size: contain;
		position: absolute;
		z-index: 1;
	}
	
	img {
		margin: auto;
		height: 100%;
		width: auto;
		z-index: 2;
		position: relative;
	}
`;

const InstagramPost = ({post}) => {
	return (
		<StyledInstagramPost>
			<div className={"bg"} style={{backgroundImage: `url(${post.src})`}} />
			<img src={post.src} />
		</StyledInstagramPost>
	);
}

const FeedVariants = {
	initial: { opacity: 1 },
	animate: {
		opacity: 1,
		transition: {
			delay: 0,
			staggerChildren: 0.1,
		},
	},
};

const FeedItemVariants = {
	initial: { scale: 1.25, opacity: 0 },
	animate: { scale: 1, opacity: 1 },
};

export const InstagramFeed = () => {
	let initialValue;

	try {
		initialValue = JSON.parse(localStorage.getItem('instagram-cache')) ?? [];
	} catch (_) {
		initialValue = [];
	}
	const [data, setData] = useState(initialValue);

	useEffect(() => {
		axios.get('/api/instagram')
			.then(feed => {
				setData(feed.data);
				localStorage.setItem('instagram-cache', JSON.stringify(feed.data));
			});

	}, []);

	return <motion.div variants={FeedVariants} initial={"initial"} whileInView={"animate"}>
		{ data.map(item => <motion.div variants={FeedItemVariants} style={{display: "inline-block"}}>
				<InstagramPost post={item} />
			</motion.div>) }
	</motion.div>;
};
