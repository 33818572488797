import {Col, Container, Row} from "react-bootstrap";
import {AlternateSection} from "../Components/Section";
import styled from "styled-components";
import {motion} from "framer-motion";

const H2 = styled.h2`
	font-size: 3rem;
	
	@media (max-width: 767px) {
		margin-bottom: 2rem;
	}
`;

const Link = styled.a`
	color: #fff;
	transition: .2s ease-in all;
	
	&:hover {
		color: #00C3FF;
	}
`;

const Icon = styled.i`
	font-size: 3rem;
	margin: 2rem;
`;

const ConnectVariants = {
	initial: { opacity: 1 },
	animate: {
		opacity: 1,
		transition: {
			delay: 0.5,
			staggerChildren: 0.075,
		},
	},
};

const ConnectItemVariants = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
};

export const ConnectSection = () => {
	return (
	<AlternateSection>
		<Container>
			<Row>
				<Col md={12} className={"text-center"}>
					<motion.div variants={ConnectVariants} initial={"initial"} whileInView={"animate"}>
						<Link as={motion.a} variants={ConnectItemVariants} href={"https://linkedin.com/in/DannyBattison"} target={"_blank"}><Icon className="fa-brands fa-linkedin-in" /></Link>
						<Link as={motion.a} variants={ConnectItemVariants} href={"https://github.com/DannyBattison"} target={"_blank"}><Icon className="fa-brands fa-github" /></Link>
						<Link as={motion.a} variants={ConnectItemVariants} href={"https://instagram.com/capnhammered"} target={"_blank"}><Icon className="fa-brands fa-instagram" /></Link>
						<Link as={motion.a} variants={ConnectItemVariants} href={"https://twitch.tv/capnhammered"} target={"_blank"}><Icon className="fa-brands fa-twitch" /></Link>
					</motion.div>
				</Col>
			</Row>
		</Container>
	</AlternateSection>
	);
}
