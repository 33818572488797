import { motion } from 'framer-motion';
import * as React from "react";

const sentence = {
	hidden: { opacity: 1 },
	visible: {
		opacity: 1,
		transition: {
			delay: 0.5,
			staggerChildren: 0.03,
		},
	},
};

const letter = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
	},
};

export const RevealText = ({children}) => {
	const modifiedChildren = [];
	for (const child of React.Children.toArray(children)) {
		modifiedChildren.push(
			React.createElement(child.type, child.props, child.props.children.split('').map((char, index) =>
				<motion.span key={char + '-' + index} variants={letter}>{char}</motion.span>
			))
		);
	}

	return (
		<motion.span
			variants={sentence}
			initial={"hidden"}
			whileInView={"visible"}
		>
			{modifiedChildren}
		</motion.span>
	);
}
