import 'bootstrap/dist/css/bootstrap.min.css';
import {HeroSection} from "./Sections/Hero";
import {ExperienceSection} from "./Sections/Experience";
import {InstagramSection} from "./Sections/Instagram";
import {ContactSection} from "./Sections/Contact";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ProgressBar} from "./Components/ProgressBar";
import {ConnectSection} from "./Sections/Connect";

function App() {
	return (
	<div className="App">
		<ToastContainer />
		<HeroSection />
		<ExperienceSection />
		<InstagramSection />
		<ContactSection />
		<ConnectSection />
		<ProgressBar />
	</div>
	);
}

export default App;
