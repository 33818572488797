import {PlainSection} from "../Components/Section";
import {Col, Container, Row} from "react-bootstrap";
import {RevealText} from "../Components/Text";
import styled from 'styled-components';
import {motion} from "framer-motion";

const H2 = styled.h2`
	font-size: 3rem;
	
	@media (max-width: 767px) {
		margin-bottom: 2rem;
	}
`;

const TimelineHeading = styled.h2`
	padding-left: 1.2rem;
	position: relative;
	margin-top: -9px;
	&:before {
		content: "•";
		position: absolute;
    font-size: 10rem;
    opacity: 0.75;
    vertical-align: middle;
    margin: -96px 0 0 -38px;
	}
`;

const TimelineItemWrap = styled.div`
	padding: 1rem 0 1rem 2rem;
	border-left: 0.15rem solid rgba(255,255,255,0.7);
	margin-top: -19px;
`;

const StyledTimelineItem = styled.div`
	margin: 0.5rem 0;
	&:before {
		content: "•";
		position: absolute;
    font-size: 6.1rem;
    opacity: 0.75;
    vertical-align: middle;
    margin: -69px 0 0 -45px;
	}
`;

const TimelineItem = ({title, start, end}) => {
	return (
		<StyledTimelineItem>
			<h4>{title}</h4>
			<small>{start} - {end}</small>
		</StyledTimelineItem>
	);
}

const TimelineVariants = {
	initial: { opacity: 1 },
	animate: {
		opacity: 1,
		transition: {
			delay: 0.5,
			staggerChildren: 0.075,
		},
	},
};

const TimelineItemVariants = {
	initial: { rotate: -15, opacity: 0 },
	animate: { rotate: 0, opacity: 1 },
};

export const ExperienceSection = () => {
	return (
		<PlainSection>
			<Container fluid={'md'}>
				<Row>
					<Col md={6}>
						<RevealText>
							<H2>Experience</H2>
						</RevealText>
					</Col>
					<Col md={6}>
						<motion.div
							variants={TimelineVariants}
							whileInView={"animate"}
							initial={"initial"}
						>
							<motion.div variants={TimelineItemVariants}>
								<TimelineHeading>Chessable</TimelineHeading>
								<TimelineItemWrap>
									<TimelineItem title={'Engineering Manager'} start={'January 2022'} end={'Present'} />
									<TimelineItem title={'Technical Team Lead'} start={'April 2021'} end={'January 2022'} />
									<TimelineItem title={'Software Engineer'} start={'November 2020'} end={'April 2021'} />
								</TimelineItemWrap>
							</motion.div>
							<motion.div variants={TimelineItemVariants}>
								<TimelineHeading>Magma Digital</TimelineHeading>
								<TimelineItemWrap>
									<TimelineItem title={'Lead Engineer'} start={'August 2019'} end={'November 2020'} />
									<TimelineItem title={'Software Engineer'} start={'February 2018'} end={'August 2019'} />
								</TimelineItemWrap>
							</motion.div>
							<motion.div variants={TimelineItemVariants}>
								<TimelineHeading>inLIFE Design</TimelineHeading>
								<TimelineItemWrap>
									<TimelineItem title={'Senior Software Engineer'} start={'July 2013'} end={'February 2018'} />
								</TimelineItemWrap>
							</motion.div>
							<motion.div variants={TimelineItemVariants}>
								<TimelineHeading>AmmNet</TimelineHeading>
								<TimelineItemWrap>
									<TimelineItem title={'Senior Software Engineer'} start={'September 2009'} end={'July 2013'} />
									<TimelineItem title={'Software Engineer'} start={'November 2008'} end={'September 2009'} />
								</TimelineItemWrap>
							</motion.div>
						</motion.div>
					</Col>
				</Row>
			</Container>
		</PlainSection>
	);
}
