import {InstagramFeed} from "../Components/InstagramFeed";
import {AlternateSection} from "../Components/Section";
import {Col, Container, Row} from "react-bootstrap";
import {RevealText} from "../Components/Text";
import styled from "styled-components";

const H2 = styled.h2`
	font-size: 3rem;
	
	@media (max-width: 767px) {
		margin-bottom: 2rem;
	}
`;

export const InstagramSection = () => {
	return (
		<AlternateSection>
			<Container>
				<Row>
					<Col>
						<RevealText>
							<H2>Instagram</H2>
						</RevealText>
						<div className={'text-center'}>
							<InstagramFeed />
						</div>
					</Col>
				</Row>
			</Container>
		</AlternateSection>
	);
}
