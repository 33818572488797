import styled from 'styled-components';
import {motion, useScroll} from 'framer-motion';

const StyledBar = styled.div`
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 0.5rem;
	background: linear-gradient(to right, #EB7500, #00C3FF);
	transform-origin: 0% center;
	z-index: 2147483647;
	opacity: 0.3;
`;

export const ProgressBar = () => {
	const { scrollYProgress } = useScroll();

	return (
		<StyledBar as={motion.div} style={{ scaleX: scrollYProgress }} />
	);
}
