import {ImpactSection} from "../Components/Section";
import {Col, Container, Row} from "react-bootstrap";
import {RevealText} from "../Components/Text";
import {Face} from "../Components/Photo";
import styled from 'styled-components';
import {motion} from "framer-motion";
import {useEffect, useRef, useState} from "react";

const SectionContent = styled(motion.div)`
	@media (min-width: 768px) {
		width: calc(100% - 14rem);
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		max-width: 1320px;
	}
`;

const H1 = styled.h1`
	font-size: 4rem;
	opacity: 0.6;
`;

const H2 = styled.h2`
	font-size: 3rem;
	
	@media (max-width: 900px) {
		font-size: 2.5rem;
	}
	
	@media (max-width: 767px) {
		font-size: 2rem;
	}
`;

const useWindowDimensions = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	useEffect(() => {
		window.addEventListener("resize", () => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		});
		return () => {
			window.removeEventListener("resize", () => {
				setWidth(window.innerWidth);
				setHeight(window.innerHeight);
			})
		}
	}, []);

	return {
		width: width,
		height: height,
	};
}

export const HeroSection = () => {
	const { width } = useWindowDimensions();

	return (
		<ImpactSection style={{height: '100vh'}}>
				<SectionContent initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { staggerChildren: 1 } }} style={width < 1100 ? {position: "relative", width: "100%", height: "100%"} : {}}>
						{
							width < 1100 && <>
								<div style={{position: "relative"}}>
									<RevealText>
										<H1>Danny Battison</H1>
										<H2>Software Engineer</H2>
										<H2>Cat Dad</H2>
										<H2>Gamer</H2>
									</RevealText>
								</div>
								<Face style={{
									position: "absolute",
									bottom: "3vh",
									left: "50%",
									transform: "translateX(-50%)",
								}}/>
							</>
						}
						{
							width >= 1100 && <>
								<Container>
									<Row className={"align-items-center"}>
										<Col sm={8}>
											<RevealText>
												<H1>Danny Battison</H1>
												{ width >= 1440 && <H2>Software Engineer | Cat Dad | Gamer</H2> }
												{ width < 1440 && <H2>Software Engineer</H2> }
												{ width < 1440 && <H2>Cat Dad</H2> }
												{ width < 1440 && <H2>Gamer</H2> }
											</RevealText>
										</Col>
										<Col sm={4}>
											<Face />
										</Col>
									</Row>
								</Container>
							</>
						}
				</SectionContent>
		</ImpactSection>
	);
}
